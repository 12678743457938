import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import ProfileDetails from "../components/profile/ProfileDetails";
import useSWR from "swr";
import { fetcher } from "../helpers/axios";

function Profile() {
  const { profileId } = useParams();
  const { data: user, error } = useSWR(`/user/${profileId}/`, fetcher);

  if (error) return <div>Failed to load</div>;
  if (!user) return <div>Loading...</div>;

  return (
    <>
      <div className="flex items-center justify-center w-full">
        <ProfileDetails user={user} />
      </div>
    </>
  );
}

export default Profile;
