import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import { routesConfig, componentsMap } from "./routes/routes";
import Layout from "./components/Layout";


function App() {
  return (
    <Layout>
      <Routes>
        {routesConfig.map((route, index) => {
          const Component = componentsMap[route.component];
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute
                  isProtected={route.protected}
                  guestOnly={route.guestOnly}
                >
                  <Component />
                </ProtectedRoute>
              }
            />
          );
        })}
      </Routes>
    </Layout>
  );
}

export default App;
