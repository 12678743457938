import React, { useEffect, useReducer, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserActions, getUser } from "../hooks/user.actions";
import LoginForm from "../components/authentication/LoginForm";

const initialState = {
  loggedIn: true,
  status: "processing",
  message: "Επεξεργασία..."
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_LOGGED_IN":
      return { ...state, loggedIn: action.payload };
    case "PROCESSING":
      return { ...state, status: "processing", message: action.payload || "Επεξεργασία..." };
    case "SUCCESS":
      return { ...state, status: "success", message: action.payload };
    case "ERROR":
      return { ...state, status: "error", message: action.payload };
    default:
      return state;
  }
}

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const userActions = useUserActions();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigationTimeout = useRef(null);
  const hasVerified = useRef(false);

  useEffect(() => {
    return () => {
      if (navigationTimeout.current) {
        clearTimeout(navigationTimeout.current);
      }
    };
  }, []);

  const verifyEmail = async (token) => {
    if (hasVerified.current) return;
    
    hasVerified.current = true;
    dispatch({ type: "PROCESSING", payload: "Επεξεργασία..." });
    
    try {
      const response = await userActions.verifyEmail(token);
      if (response.error) {
        dispatch({ type: "ERROR", payload: response.error });
      } else {
        dispatch({
          type: "SUCCESS",
          payload: "Η επαλήθευση ολοκληρώθηκε με επιτυχία. Ανακατεύθυνση..."
        });

        // Fetch fresh user data and update localStorage
        try {
          await userActions.fetchCurrentUser();
          navigationTimeout.current = setTimeout(() => {
            navigate("/", { replace: true });
          }, 3000);
        } catch (error) {
          console.error("Error updating user data:", error);
          // Still navigate even if fetch fails
          navigationTimeout.current = setTimeout(() => {
            navigate("/", { replace: true });
          }, 3000);
        }
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: "Η επαλήθευση απέτυχε. Παρακαλώ προσπαθήστε ξανά."
      });
    }
  };

  useEffect(() => {
    verifyEmail(token);
  }, [token]);

  const handleLogin = async (data) => {
    try {
      const user = await userActions.login(data);
      dispatch({ type: "SET_LOGGED_IN", payload: true });
      if (user.is_verified) {
        navigate("/");
      } else {
        hasVerified.current = false;
        verifyEmail(token);
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: "Η σύνδεση απέτυχε. Παρακαλώ προσπαθήστε ξανά."
      });
    }
  };

  const statusIcons = {
    processing: "⌛",
    success: "✅",
    error: "⚠️"
  };

  return (
    <div className="min-h-screen bg-base-100 flex items-center justify-center p-4">
      <div className="w-full max-w-2xl">
        <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
          <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center text-2xl">
                {statusIcons[state.status]}
              </div>
              <h2 className="mt-4 text-2xl font-semibold text-base-content">
                Επαλήθευση Email
              </h2>
              <p className="mt-2 text-base-content/70 text-center max-w-sm">
                {state.status === "processing" && "Επεξεργαζόμαστε το αίτημα επαλήθευσης"}
                {state.status === "success" && "Η επαλήθευση ολοκληρώθηκε"}
                {state.status === "error" && "Παρουσιάστηκε σφάλμα στην επαλήθευση"}
              </p>
            </div>
          </div>

          <div className="p-6 space-y-6">
            <div className="bg-base-200 rounded-lg p-6">
              <div
                className={`text-center space-y-4 ${
                  state.status === "processing"
                    ? "text-base-content/70"
                    : state.status === "success"
                    ? "text-success"
                    : "text-error"
                }`}
              >
                <div className="text-lg font-medium">{state.message}</div>
                {state.status === "processing" && (
                  <div className="flex justify-center">
                    <span className="loading loading-spinner loading-lg text-primary"></span>
                  </div>
                )}
              </div>
            </div>

            {!state.loggedIn && (
              <div className="bg-base-200 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-base-content mb-4">
                  Σύνδεση
                </h3>
                <p className="text-base-content/70 mb-4">
                  Παρακαλώ συνδεθείτε για να συνεχίσετε με την επαλήθευση του email σας.
                </p>
                <LoginForm onSubmit={handleLogin} />
              </div>
            )}

            <div className="text-xs text-base-content/50 text-center">
              Αν αντιμετωπίζετε προβλήματα, παρακαλούμε επικοινωνήστε με την υποστήριξη
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;