import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navigationbar from "./Navbar";
import Footer from "./Footer";
import Toaster from "./Toaster";
import ThemeContext from "../helpers/context/themeContext";

function Layout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useContext(ThemeContext);

  // Define routes that should show the back button
  const pagesWithBackButton = ["/profile", "/settings", "/subscription"];
  const hasNavigationBack = pagesWithBackButton.some(path => location.pathname.startsWith(path));

  // Define routes that should **not** have a navbar or padding
  const noNavbarRoutes = ["/login", "/register", "/forgot-password","/reset-password", "/verify-email-notice", "/verify-email"];
  const isNoNavbar = noNavbarRoutes.some(path => location.pathname.startsWith(path));

  return (
    <div className={`flex flex-col min-h-screen bg-base-100`} data-theme={theme}>
      {/* Conditionally render navbar */}
      {!isNoNavbar && <Navigationbar />}

      {/* Back Button Logic */}
      {!isNoNavbar && hasNavigationBack && (
        <div
          title="back"
          className="text-primary text-xl cursor-pointer ml-[5%] mt-5 px-2 w-fit inline-flex"
          onClick={() => navigate(-1)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-10">
            <path fillRule="evenodd" d="M7.28 7.72a.75.75 0 0 1 0 1.06l-2.47 2.47H21a.75.75 0 0 1 0 1.5H4.81l2.47 2.47a.75.75 0 1 1-1.06 1.06l-3.75-3.75a.75.75 0 0 1 0-1.06l3.75-3.75a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
          </svg>
        </div>
      )}

      {/* Main Content Area */}
      <div className={`flex justify-center items-center w-full grow ${isNoNavbar ? "" : "container my-10 mx-auto"}`}>
        {children}
      </div>

      {/* Show footer only if navbar is present */}
      {!isNoNavbar && <Footer />}
      
      <Toaster />
    </div>
  );
}

export default Layout;
