import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";

import { getUser } from "../hooks/user.actions";

import CategoryList from '../components/categories/CategoryList';
import Preloader from '../components/Preloader';

function Home() {

  const user = getUser();

  if (!user) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-base-200">
        <Preloader />
      </div>
    );
  }

  // Add expired membership notification
  const renderMembershipAlert = () => {
    if (user.membership?.status?.toLowerCase() === 'expired') {
      return (
        <div className="alert alert-error shadow-lg max-w-4xl mx-auto mb-8 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <div className="flex flex-col">
              <h3 className="font-bold">Η συνδρομή σας έχει λήξει!</h3>
              <div className="text-sm">Ανανεώστε τη συνδρομή σας για να συνεχίσετε να λαμβάνετε ειδοποιήσεις.</div>
            </div>
          </div>
          <Link to={`/profile/${user.id}/subscription/`} className="btn btn-sm ml-4">
            Ανανέωση Συνδρομής
          </Link>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="max-w-4xl mx-auto px-4 py-8">
        {renderMembershipAlert()}
        <div className="text-center mb-8">
          <h1 className="text-3xl text-base-content font-bold mb-6">Καλώς ήρθατε στο public-contracts.gr!</h1>
          <p className="text-lg mb-6 hidden">
            Επιλέξτε τις κατηγορίες διαγωνισμών που σας ενδιαφέρουν και λάβετε καθημερινά ενημερώσεις 
            για νέες προκηρύξεις απευθείας στο email σας.
          </p>
        </div>

        <div className="mb-8 flex justify-center items-center flex-col">
          <div className="rounded-lg  bg-base-300  p-6 mb-8 text-center max-w-2xl">
          <h2 className="text-xl font-semibold mb-4 text-primary">Πώς λειτουργεί:</h2>
          <ol className="list-decimal list-inside space-y-2 mb-4">
            <li>Επιλέξτε μία ή περισσότερες κατηγορίες από τη λίστα</li>
            <li>Από την επόμενη ημέρα θα λαμβάνετε ειδοποιήσεις για νέους διαγωνισμούς</li>
            <li>Μπορείτε να τροποποιήσετε τις επιλογές σας οποιαδήποτε στιγμή</li>
          </ol>
        </div>
          <div className="flex justify-center">
            <div className="flex flex-col items-center w-full max-w-2xl">
              <h2 className="card-title text-center text-info mb-5">Διαθέσιμες Κατηγορίες</h2>
              <CategoryList />
            </div>
          </div>
        </div>

        <div className="hidden bg-base-content rounded-lg p-6 mb-8 text-center text-sm mt-8">
          <p className="flex items-center justify-center text-secondary-content">
            <span className="text-lg text-accent mr-2">ℹ️</span>
            Οι κατηγορίες βασίζονται στο Κοινό Λεξιλόγιο για τις Δημόσιες Συμβάσεις (CPV) 
            της Ευρωπαϊκής Ένωσης. Μπορείτε να επιλέξετε όσες κατηγορίες επιθυμείτε και 
            να τροποποιήσετε τις επιλογές σας οποιαδήποτε στιγμή από το προφίλ σας.
          </p>
        </div>
      </div>
    </>
  );
}

export default Home;