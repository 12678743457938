import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosService from "../helpers/axios";

function useUserActions() {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_URL;

  // Login the user
  function login(data) {
    return axios.post(`${baseURL}/auth/login/`, data).then((res) => {
      setUserData(res.data);
      navigate("/"); // Redirect after login
      return res.data.user;
    });
  }

  // Fetch current user
  function fetchCurrentUser() {
    return axiosService.get(`${baseURL}/user/me/`).then((res) => {
      localStorage.setItem(
        "auth",
        JSON.stringify({
          access: getAccessToken(),
          refresh: getRefreshToken(),
          user: res.data,
        })
      );
      return res.data;
    });
  }

  // Register the user
  function register(data) {
    return axios.post(`${baseURL}/auth/register/`, data).then((res) => {
      // Registering the account and tokens in the store
      setUserData(res.data);
      navigate("/verify-email-notice"); // Redirect after registration
    });
  }

  // Verify Email
  function verifyEmail(token) {
    return axiosService.get(`${baseURL}/user/verify-email/${token}/`).then((res) => {
      return res.data;
    });
  }

  // Resend Verification Email
  function resendVerificationEmail() {
    return axiosService.post(`${baseURL}/user/send-verification-email/`).then((res) => {
      return res.data;
    });
  }

  // Edit the user
  function edit(data, userId) {
    return axiosService
      .patch(`${baseURL}/user/${userId}/`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // Registering the account in the store
        localStorage.setItem(
          "auth",
          JSON.stringify({
            access: getAccessToken(),
            refresh: getRefreshToken(),
            user: res.data,
          })
        );
      });
  }

  // Change the password
  function changePassword(data) {
    return axiosService
      .post(`${baseURL}/user/change-password/`, data)
      .then((res) => {
        // Optionally handle response or update user information in localStorage
        return res.data;
      });
  }

  // Subscribe the user
  function updateNewsletterSubscriptions(subscriptionData) {
    return axiosService
      .post(`${baseURL}/newsletter-subscriptions/subscriptions/update-subscriptions/`, subscriptionData)
      .then((res) => {
        // Optionally handle the response or update the user information in localStorage
        return res.data;
      });
  }

  // Cancel membership
  function cancelMembership() {
    return axiosService.post(`${baseURL}/subscription/cancel/`).then((res) => {
      return res.data;
    });
  }

  // Logout the user
  function logout() {
    localStorage.removeItem("auth");
    navigate("/login");
  }

  return {
    login,
    fetchCurrentUser,
    register,
    logout,
    verifyEmail,
    resendVerificationEmail,
    edit,
    changePassword,
    updateNewsletterSubscriptions,
    cancelMembership,
  };
}

// Get the user
function getUser() {
  const auth = JSON.parse(localStorage.getItem("auth")) || null;
  if (auth) {
    return auth.user;
  } else {
    return null;
  }
}

// Get the access token
function getAccessToken() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return auth.access;
}

// Get the refresh token
function getRefreshToken() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  return auth.refresh;
}

// Set the access, token and user property
function setUserData(data) {
  localStorage.setItem(
    "auth",
    JSON.stringify({
      access: data.access,
      refresh: data.refresh,
      user: data.user,
    })
  );
}

export { useUserActions, getUser, getAccessToken, getRefreshToken, setUserData };
