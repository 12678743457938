import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import SubscriptionDetails from "../components/subscription/SubscriptionDetails";
import useSWR from "swr";
import { fetcher } from "../helpers/axios";

function Subscription() {
  const { profileId } = useParams();
  const { data: subscriptionData, error } = useSWR(`/subscription/`, fetcher);

  if (error) return <div>Failed to load</div>;
  if (!subscriptionData) return <div>Loading...</div>;

  return (
    <div className="flex items-center justify-center w-full">
      <SubscriptionDetails subscription={subscriptionData} />
    </div>
  );
}

export default Subscription;