import React from 'react';
import { Link } from 'react-router-dom';
import Layout from "../../components/Layout";

const Cancel = () => {
    return (
        <Layout>
            <div className="min-h-[50vh] flex items-center justify-center p-4">
                <div className="w-full max-w-2xl">
                    <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
                        {/* Header Section */}
                        <div className="bg-gradient-to-r from-error/10 via-error/5 to-base-100 p-6">
                            <div className="flex flex-col items-center">
                                <div className="w-16 h-16 rounded-full bg-error/10 flex items-center justify-center text-2xl">
                                    ❌
                                </div>
                                <h2 className="mt-4 text-2xl font-semibold text-base-content">
                                    Η Πληρωμή Ακυρώθηκε
                                </h2>
                                <p className="mt-2 text-base-content/70 text-center max-w-sm">
                                    Η διαδικασία πληρωμής ακυρώθηκε. Μπορείτε να προσπαθήσετε ξανά όποτε θέλετε.
                                </p>
                            </div>
                        </div>

                        {/* Content Section */}
                        <div className="p-6">
                            <div className="flex justify-center">
                                <Link 
                                    to="/profile/${user.id}/subscription/" 
                                    className="btn btn-error"
                                >
                                    Επιστροφή στο Προφίλ
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Cancel;