import React from "react";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "../components/authentication/ForgotPasswordForm";

function ForgotPassword() {
  return (
    <div className="min-h-screen w-full bg-base-300 flex items-center justify-center p-4">
      <div className="w-full max-w-xl">
        <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center text-2xl">
                🔑
              </div>
              <h1 className="mt-4 text-2xl font-semibold text-base-content">
                Επαναφορά Κωδικού
              </h1>
              <p className="mt-2 text-base-content/70 text-center max-w-sm">
                Εισάγετε το email σας για να λάβετε οδηγίες επαναφοράς του κωδικού σας
              </p>
            </div>
          </div>

          {/* Form Section */}
          <div className="p-6">
            <div className="bg-base-200 rounded-lg p-6">
              <ForgotPasswordForm />
              
              <div className="mt-6 text-center text-sm text-base-content/70">
                Θυμηθήκατε τον κωδικό σας;{" "}
                <Link 
                  to="/login/" 
                  className="text-primary hover:text-primary/80 font-medium transition-colors"
                >
                  Επιστροφή στη σύνδεση
                </Link>
              </div>
            </div>
          </div>

          {/* Optional Security Note */}
          <div className="px-6 pb-6">
            <div className="text-xs text-base-content/50 text-center">
              Για την ασφάλειά σας, ο σύνδεσμος επαναφοράς κωδικού θα λήξει σε 60 λεπτά
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;