import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserActions } from "../../hooks/user.actions";
import { useToaster } from "../../helpers/context/toasterContext";
import EyeIcon from "../../components/ui/EyeIcon"; 
import EyeSlashIcon from "../../components/ui/EyeSlashIcon"; 


function ChangePasswordForm() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: ""
  });
  const [error, setError] = useState(null);
  const { setToaster } = useToaster();
  const userActions = useUserActions();
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPasswords, setShowPasswords] = useState({
    old: false,
    new: false,
    confirm: false
  });

  useEffect(() => {
    setIsFormValid(
      form.old_password.trim() !== '' && 
      form.new_password.trim() !== '' && 
      form.confirm_new_password.trim() !== ''
    );
  }, [form]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (form.new_password !== form.confirm_new_password) {
      setError("New passwords do not match");
      return;
    }

    try {
      await userActions.changePassword(form);
      setToaster({
        show: true,
        type: "success",
        message: "Ο κωδικός σας άλλαξε με επιτυχία",
        title: "Αλλαγή Κωδικού",
        autoHide: false
      });
      navigate("/");
    } catch (err) {
      const errorMessage = err?.response?.data?.detail || "An error occurred";
      setError(errorMessage);
      setToaster({
        type: "error",
        message: errorMessage,
        show: true,
        title: "Αποτυχία Αλλαγής",
      });
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
        {/* Header Section */}
        <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6">
          <div className="flex flex-col items-center">
            <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center text-2xl font-bold text-primary">
              🔒
            </div>
            <h2 className="mt-4 text-2xl font-semibold text-base-content">Αλλαγή Κωδικού</h2>
            <p className="mt-2 text-base-content/70 text-center">
              Παρακαλώ πληκτρολογηστε τον τωρινό κωδικό σας και τον καινούριο κωδικό
            </p>
          </div>
        </div>

        {/* Form Section */}
        <div className="p-6 space-y-6">
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Current Password */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-base-content/70">
                Παλιός Κωδικός
              </label>
              <div className="relative">
                <input
                  type={showPasswords.old ? "text" : "password"}
                  placeholder="Εισάγετε τον παλιό σας κωδικό"
                  value={form.old_password}
                  className="input input-bordered w-full pr-20"
                  onChange={(e) => setForm({ ...form, old_password: e.target.value })}
                  required
                />
                {showPasswords.old ? (
                  <EyeSlashIcon
                    onClick={() => togglePasswordVisibility("old")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors"
                  />
                ) : (
                  <EyeIcon
                    onClick={() => togglePasswordVisibility("old")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors"
                  />
                )}
              </div>
            </div>

            {/* New Password */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-base-content/70">
                Νεος Κωδικός
              </label>
              <div className="relative">
                <input
                  type={showPasswords.new ? "text" : "password"}
                  placeholder="Εισάγετε τον νέο κωδικό"
                  value={form.new_password}
                  className="input input-bordered w-full pr-20"
                  onChange={(e) => setForm({ ...form, new_password: e.target.value })}
                  required
                />
                {showPasswords.new ? (
                  <EyeSlashIcon
                    onClick={() => togglePasswordVisibility("new")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors"
                  />
                ) : (
                  <EyeIcon
                    onClick={() => togglePasswordVisibility("new")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors"
                  />
                )}
              </div>
            </div>

            {/* Confirm New Password */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-base-content/70">
                Επαλήθευση
              </label>
              <div className="relative">
                <input
                  type={showPasswords.confirm ? "text" : "password"}
                  placeholder="Επιβεβαιώστε τον νέο κωδικό"
                  value={form.confirm_new_password}
                  className="input input-bordered w-full pr-20"
                  onChange={(e) => setForm({ ...form, confirm_new_password: e.target.value })}
                  required
                />
                {showPasswords.confirm ? (
                  <EyeSlashIcon
                    onClick={() => togglePasswordVisibility("confirm")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors"
                  />
                ) : (
                  <EyeIcon
                    onClick={() => togglePasswordVisibility("confirm")}
                    className="absolute right-3 top-1/2 -translate-y-1/2 w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors"
                  />
                )}
              </div>
            </div>

            {/* Error Message */}
            {error && (
              <div className="bg-error/10 text-error rounded-lg p-4">
                {error}
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              disabled={!isFormValid}
              className="btn btn-primary w-full mt-6"
            >
              Αλλαγή
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordForm;