import React, { useState, useEffect } from 'react';
import NoticeCard from './NoticeCard';
import Preloader from '../Preloader';

const FilterSkeleton = () => (
  <div className="animate-pulse space-y-4">
    {[...Array(8)].map((_, index) => (
      <div key={index} className="flex items-center space-x-3">
        <div className="w-4 h-4 bg-base-300 rounded"></div>
        <div className="flex-1">
          <div className="h-4 bg-base-300 rounded w-3/4"></div>
          <div className="h-3 bg-base-300 rounded w-1/4 mt-1 opacity-70"></div>
        </div>
      </div>
    ))}
  </div>
);

const DailyNoticesList = ({ 
  notices, 
  noticesCount, 
  isLoading,
  nextPage,
  previousPage,
  cpv,
  date,
  onFetchNotices,
  onFetchFilterOptions,
  onUpdateURL, 
  initialSubcategories = [] 
}) => {
  const [subcategoryCounts, setSubcategoryCounts] = useState({});
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [pendingSubcategories, setPendingSubcategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const pageSize = 15;

  useEffect(() => {
    setSelectedSubcategories(initialSubcategories);
    setPendingSubcategories(initialSubcategories);
  }, [initialSubcategories]);
  
  useEffect(() => {
    const loadFilterOptions = async () => {
      const options = await onFetchFilterOptions(cpv, date);
      setSubcategoryCounts(options);
    };
    loadFilterOptions();
  }, [cpv, date, onFetchFilterOptions]);

  const handleCheckboxChange = (cpv_code) => {
    const newPendingSubcategories = pendingSubcategories.includes(cpv_code)
      ? pendingSubcategories.filter((item) => item !== cpv_code)
      : [...pendingSubcategories, cpv_code];
  
    setPendingSubcategories(newPendingSubcategories);
    setHasChanges(JSON.stringify(newPendingSubcategories.sort()) !== JSON.stringify(selectedSubcategories.sort()));
  };

  const handleApplyFilters = () => {
    setSelectedSubcategories(pendingSubcategories);
    setCurrentPage(1);
    onUpdateURL(pendingSubcategories, 1);
    onFetchNotices(cpv, date, pendingSubcategories, 1);
    setHasChanges(false);
  };

  const handleClearFilters = () => {
    if (selectedSubcategories.length > 0 || pendingSubcategories.length > 0) {
      setSelectedSubcategories([]);
      setPendingSubcategories([]);
      setCurrentPage(1);
      onUpdateURL([], 1);
      onFetchNotices(cpv, date, [], 1);
      setHasChanges(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && ((nextPage && newPage > currentPage) || (previousPage && newPage < currentPage))) {
      setCurrentPage(newPage);
      onUpdateURL(selectedSubcategories, newPage);
      onFetchNotices(cpv, date, selectedSubcategories, newPage);
    }
  };

  // Keep original filter content rendering
  const renderFilterContent = () => {
    if (isLoading && Object.keys(subcategoryCounts).length === 0) {
      return <FilterSkeleton />;
    }
    
    if (!isLoading && Object.keys(subcategoryCounts).length === 0) {
      return (
        <div className="p-4 text-center text-base-content/70">
          Δεν βρέθηκαν φίλτρα
        </div>
      );
    }

    return Object.keys(subcategoryCounts).map(cpv_code => (
      <div key={cpv_code}>
        <label className="cursor-pointer">
          <input
            type="checkbox"
            checked={pendingSubcategories.includes(cpv_code)}
            onChange={() => handleCheckboxChange(cpv_code)}
            className="mr-2 checkbox checkbox-accent checkbox-xs"
          />
          <span className="relative bottom-[2px]">
            {subcategoryCounts[cpv_code]?.name} ({subcategoryCounts[cpv_code]?.count})
          </span>
        </label>
      </div>
    ));
  };

  // Keep original notice grouping logic
  const groupedNotices = notices.reduce((acc, notice) => {
    const category = notice.category.name;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(notice);
    return acc;
  }, {});

  const displayStart = (currentPage - 1) * pageSize + 1;
  const displayEnd = Math.min(currentPage * pageSize, noticesCount);
  let globalIndex = 0;

  return (
    <div className="drawer">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col lg:flex-row">
        {/* Desktop filter area */}
        <div className="lg:w-1/4 p-4 lg:sticky lg:top-0 lg:h-screen lg:flex lg:flex-col">
          <h2 className="text-xl mb-4 hidden lg:inline">Υποκατηγορίες</h2>
          <label
            htmlFor="my-drawer"
            className="btn btn-accent drawer-button lg:hidden fixed top-20 right-0 rounded-none z-30"
          >
            Φίλτρα
          </label>
          <div className="hidden lg:flex lg:flex-col lg:h-full relative">
            <div className="flex-grow overflow-y-auto">
              {renderFilterContent()}
              <div className="min-h-[40px]"></div>
            </div>
            <div className="w-full sticky h-[60px] bg-base-100 bottom-0 pt-4 flex justify-between p-4 shadow-2xl">
              <button 
                className="btn btn-info btn-sm" 
                onClick={handleApplyFilters} 
                disabled={!hasChanges}
              >
                Εφαρμογή
              </button>
              <button 
                className="btn btn-error btn-sm" 
                onClick={handleClearFilters} 
                disabled={selectedSubcategories.length === 0 && pendingSubcategories.length === 0}
              >
                Καθαρισμός
              </button>
            </div>
          </div>
        </div>

        <div className="lg:w-3/4 p-4">
          <div className="top-0 p-4 z-10 lg:hidden">
            {selectedSubcategories.length > 0 && (
              <div className="text-center space-x-2">
                <strong>Υποκατηγορίες</strong>
                <div className="flex text-left overflow-x-auto flex-wrap justify-start items-start">
                  {selectedSubcategories.map(cpv_code => (
                    <span key={cpv_code} className="whitespace-nowrap overflow-hidden text-ellipsis w-[45%] m-2 p-2 bg-base-200">
                      {subcategoryCounts[cpv_code]?.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

          <h2 className="text-xl text-center md:text-left mb-4">Διαγωνισμοί</h2>
          {isLoading ? (
            <div className="min-h-[400px] flex justify-center md:justify-start items-center md:items-start">
              <Preloader />
            </div>
          ) : (
            <>
              <p className="mb-4">
                Αποτελέσματα {displayStart}-{displayEnd} απο {noticesCount}
              </p>
              {Object.keys(groupedNotices).map((category, categoryIndex) => (
                <div 
                  key={category} 
                  className="my-0 relative animate-[slideUp_0.4s_ease-out] opacity-0"
                  style={{
                    animationDelay: `${categoryIndex * 0.1}s`,
                    animationFillMode: 'forwards'
                  }}
                >
                  {groupedNotices[category].map((notice, index) => {
                    globalIndex++;
                    const bgClass = globalIndex % 2 === 0 ? "bg-base-300" : "bg-base-200";
                    return (
                      <NoticeCard
                        key={notice.id}
                        notice={notice}
                        index={index}
                        bgClass={bgClass}
                      />
                    );
                  })}
                </div>
              ))}

              <div className="join grid grid-cols-3 w-full max-w-[400px] m-auto mt-5">
                <button 
                  className="join-item btn btn-outline" 
                  onClick={() => handlePageChange(currentPage - 1)} 
                  disabled={!previousPage}
                >
                  «
                </button>
                <button className="join-item btn btn-outline">
                  Σελ {currentPage} / {Math.ceil(noticesCount / pageSize)}
                </button>
                <button 
                  className="join-item btn btn-outline" 
                  onClick={() => handlePageChange(currentPage + 1)} 
                  disabled={!nextPage}
                >
                  »
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Mobile drawer */}
      <div className="drawer-side z-40">
        <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul className="menu p-4 w-80 min-h-full bg-base-200 flex flex-col">
          <h3 className="text-lg mb-2">Υποκατηγορίες</h3>
          <div className="flex-grow overflow-y-auto">
            {renderFilterContent()}
          </div>
          <div className="min-h-[50px]"></div>
        </ul>
        <div className="mt-auto p-5 w-80 h-[60px] fixed bottom-0 pt-4 flex justify-between bg-base-100">
          <button className="btn btn-info btn-sm" onClick={handleApplyFilters} disabled={!hasChanges}>
            Εφαρμογή
          </button>
          <button className="btn btn-error btn-sm" onClick={handleClearFilters} disabled={selectedSubcategories.length === 0 && pendingSubcategories.length === 0}>
            Καθαρισμός
          </button>
        </div>
      </div>

      <style jsx>{`
        @keyframes slideUp {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default DailyNoticesList;