import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserActions, getUser } from "../hooks/user.actions";

const VerifyEmailNotice = () => {
  const userActions = useUserActions();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/login/");
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const fetchedUser = await userActions.fetchCurrentUser();
        if (fetchedUser.is_verified) {
          navigate("/");
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        const localUser = getUser();
        if (localUser && localUser.is_verified) {
          navigate("/");
        }
      }
    };

    fetchUser();
  }, [navigate, userActions]);

  const handleResendEmail = async () => {
    setLoading(true);
    try {
      await userActions.resendVerificationEmail();
      setMessage("Το email επαλήθευσης στάλθηκε. Παρακαλώ ελέγξτε το inbox σας.");
      setIsError(false);
    } catch (error) {
      setMessage("Αποτυχία αποστολής email. Παρακαλώ δοκιμάστε ξανά.");
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-base-300 flex items-center justify-center p-4">
      <div className="w-full max-w-2xl">
        <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-primary/10 via-primary/5 to-base-100 p-6">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center text-2xl">
                ✉️
              </div>
              <h2 className="mt-4 text-2xl font-semibold text-base-content">
                Επαλήθευση Email
              </h2>
              <p className="mt-2 text-base-content/70 text-center max-w-sm">
                Απαιτείται επαλήθευση του email σας για να συνεχίσετε
              </p>
            </div>
          </div>

          {/* Content Section */}
          <div className="p-6 space-y-6">
            {/* Main Info Card */}
            <div className="bg-base-200 rounded-lg p-6">
              <div className="space-y-4">
                <div className="text-center space-y-2">
                  <p className="text-base-content/80">
                    Για να ολοκληρώσετε την εγγραφή σας, παρακαλούμε:
                  </p>
                  <ol className="text-left text-base-content/70 space-y-2 max-w-md mx-auto">
                    <li className="flex items-start gap-2">
                      <span className="font-semibold text-primary">1.</span>
                      <span>Πατήστε το κουμπί "Αποστολή Email" παρακάτω</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="font-semibold text-primary">2.</span>
                      <span>Ελέγξτε το email σας για το σύνδεσμο επαλήθευσης</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="font-semibold text-primary">3.</span>
                      <span>Ακολουθήστε το σύνδεσμο για να επαληθεύσετε το λογαριασμό σας</span>
                    </li>
                  </ol>
                </div>

                {/* Send Email Button */}
                <div className="pt-4">
                  <button
                    onClick={handleResendEmail}
                    className="btn btn-primary w-full"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="flex items-center gap-2">
                        <span className="loading loading-spinner loading-sm"></span>
                        Αποστολή...
                      </span>
                    ) : (
                      "Αποστολή Email"
                    )}
                  </button>
                </div>

                {/* Status Message */}
                {message && (
                  <div className={`rounded-lg p-4 text-sm ${
                    isError 
                      ? 'bg-error/10 text-error' 
                      : 'bg-success/10 text-success'
                  }`}>
                    {message}
                  </div>
                )}
              </div>
            </div>

            {/* Help Card */}
            <div className="bg-base-200 rounded-lg p-4">
              <div className="flex items-center justify-between text-sm">
                <span className="text-base-content/70">
                  Δεν θέλετε να επαληθεύσετε τώρα;
                </span>
                <button
                  onClick={handleLogout}
                  className="text-primary hover:text-primary/80 font-medium transition-colors"
                >
                  Αποσύνδεση
                </button>
              </div>
            </div>

            {/* Note */}
            <div className="text-xs text-base-content/50 text-center">
              Αν δεν λάβετε το email, ελέγξτε το φάκελο ανεπιθύμητης αλληλογραφίας (spam)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailNotice;