import React from "react";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm";

function ResetPassword() {
  return (
    <div className="min-h-screen w-full bg-base-300 flex items-center justify-center p-4">
      <div className="w-full max-w-xl">
        <div className="bg-base-100 rounded-xl shadow-lg overflow-hidden">
          {/* Header Section - Using warning/reset themed colors */}
          <div className="bg-gradient-to-r from-warning/10 via-warning/5 to-base-100 p-6">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-warning/10 flex items-center justify-center text-2xl">
                🔐
              </div>
              <h1 className="mt-4 text-2xl font-semibold text-base-content">
                Αλλαγή Κωδικού
              </h1>
              <p className="mt-2 text-base-content/70 text-center max-w-sm">
                Παρακαλώ εισάγετε τον νέο σας κωδικό
              </p>
            </div>
          </div>

          {/* Form Section */}
          <div className="p-6">
            <div className="bg-base-200 rounded-lg p-6">
              {/* Password Requirements */}
              <div className="mb-6 space-y-3">
                <h2 className="text-sm font-medium text-base-content/70">
                  Ο κωδικός σας πρέπει να περιέχει:
                </h2>
                <ul className="text-xs space-y-2 text-base-content/60">
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-warning"></span>
                    Τουλάχιστον 8 χαρακτήρες
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-warning"></span>
                    Τουλάχιστον ένα κεφαλαίο γράμμα
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-warning"></span>
                    Τουλάχιστον έναν αριθμό
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-warning"></span>
                    Τουλάχιστον ένα ειδικό χαρακτήρα
                  </li>
                </ul>
              </div>

              {/* Reset Password Form */}
              <ResetPasswordForm />
            </div>
          </div>

          {/* Security Note */}
          <div className="px-6 pb-6">
            <div className="rounded-lg bg-warning/5 p-4">
              <p className="text-xs text-base-content/70 text-center">
                Για την ασφάλειά σας, ο σύνδεσμος επαναφοράς κωδικού θα λήξει σε 60 λεπτά.
                Μετά την αλλαγή του κωδικού, θα χρειαστεί να συνδεθείτε ξανά.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;