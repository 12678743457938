import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserActions } from "../../hooks/user.actions";
import EyeIcon from "../../components/ui/EyeIcon";
import EyeSlashIcon from "../../components/ui/EyeSlashIcon";


function LoginForm({ onSubmit }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const userActions = useUserActions();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginForm = event.currentTarget;

    if (loginForm.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    setValidated(true);

    const data = {
      email: form.email,
      password: form.password,
    };

    try {
      if (onSubmit) {
        await onSubmit(data);
      } else {
        await userActions.login(data);
        const from = location.state?.from?.pathname || "/";
        navigate(from);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.detail || "Παρουσιάστηκε ένα σφάλμα");
      } else {
        setError("Παρουσιάστηκε ένα σφάλμα");
      }
    }
  };

  return (
    <form
      id="login-form"
      className="border border-primary p-4 rounded"
      noValidate
      onSubmit={handleSubmit}
      data-testid="login-form"
    >
      <div className="mb-3">
        <label className="block text-sm font-medium">Email</label>
        <input
          value={form.email}
          data-testid="username-field"
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          required
          type="email"
          placeholder="Εισαγωγή Email"
          className="mt-1 block w-full px-3 py-2 border border-primary"
        />
        <div className="hidden text-red-500 text-sm mt-1">
          Παρακαλώ εισάγετε ένα έγκυρο Email
        </div>
      </div>

      <div className="mb-3 relative">
        <label className="block text-sm font-medium">Password</label>
        <input
          value={form.password}
          data-testid="password-field"
          minLength="8"
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          required
          type={showPassword ? "text" : "password"} // Toggle between text and password
          placeholder="Εισαγωγή Κωδικού"
          className="mt-1 block w-full px-3 py-2 border border-primary"
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 right-0 top-6 pr-3 flex items-center text-sm leading-5 text-primary"
        >
        {showPassword ? (
          <EyeSlashIcon 
            onClick={() => setShowPassword(!showPassword)} 
            className="w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors" 
          />
        ) : (
          <EyeIcon 
            onClick={() => setShowPassword(!showPassword)} 
            className="w-6 h-6 text-primary cursor-pointer hover:text-primary/70 transition-colors" 
          />
        )}
        </button>
        <div className="hidden text-red-500 text-sm mt-1">
          Παρακαλώ εισάγετε έναν έγκυρο κωδικό πρόσβασης
        </div>
      </div>

      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}

      <button
        type="submit"
        className="btn btn-info mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Σύνδεση
      </button>
      <div className="mt-3 text-center">
        <a href="/forgot-password" className="text-sm text-primary hover:underline">Ξέχασα τον Κωδικό μου</a>
      </div>
    </form>
  );
}

export default LoginForm;
